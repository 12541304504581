export default {

    menuItems: [
      {
        name: 'HOME',
        //url: '/?id=1111'

        //上記と同じことだがquery使用して、
        //url: {path: "/",query: {id: '1' }}

        //propsでコールしてる
        url: { name: 'home', params: { id: '1' }}
      },

      {
        name: '営業内容',
        //propsでコールしてる
        url: { name: 'about', params: { id: '2' }}

        //query使用して、
        //url: {path: "/about",query: {id: '2' }}

        //pathではなく名前付きルートaboutでコールしてる
        //url: { name: 'about', params: { userId: 123 }}
      },

      {
        name: '料金',

        //propsでコールしてる
        url: { name: 'ryoukin', params: { id: '3' }}

        //query使用して、
        //url: {path: "/ryoukin",query: {id: '3' }}

        //pathではなく名前付きルートaboutでコールしてる
        //url: { name: 'ryoukin', params: { userId: 456 }}
      },
      {
        name: '提携店舗',
        //propsでコールしてる
        url: { name: 'tenpo', params: { id: '4' }}

        //url: {path: "/tenpo",query: {id: '4' }}
      },
      {
        name: '地図',
        //propsでコールしてる
        url: { name: 'map', params: { id: '5' }}

        //url: {path: "/map",query: {id: '5' }}
      },
      {
        name: 'サイトマップ',
        //propsでコールしてる
        url: { name: 'site', params: { id: '6' }}

        //url: {path: "/site",query: {id: '6' }}
      },
      {
        name: 'お問合せ',
        //propsでコールしてる
        url: { name: 'toiawase', params: { id: '7' }}

        //url: {path: "/toiawase",query: {id: '7' }}
      },
      /*
      {
        name: 'Database',
        url: {path: "/database",query: {id: '8' }}
      },
      */ 
    ],


    
    h1title: [
      {
        id:  '1',
        title: '川西駐車場 阪急川西能勢口駅近く安い最大料金駐車場・月極駐車場'
      },
      {
        id: '2',
        title: '川西能勢口駅近い時間貸し、月極め駐車場'
      },
      {
        id: '3',
        title: '川西能勢口駅駐車場 最大料金安い！月極駐車場も安い！'
      },
      {
      id: '4',
        title: '川西能勢口駅駐車場 ランチ、カフェ、ラーメン店等提携店多く'
      },
      {
      id: '5',
        title: '阪急川西能勢口駅、JR川西池田駅近く低価格駐車場・月極駐車場'
      },
      {
      id: '6',
        title: 'サイトマップ - 川西市の川西駐車場'
      },
      {
        id: '7',
          title: 'お問合せ - 川西市の川西駐車場'
      },
      /*
      {
        id: '8',
          title: 'Database - 川西市の川西駐車場'
      },
      */

    ],
    

    newsItems: [
      {
        src: require('../assets/p1-2.jpg'),
        title: '月極駐車場',
        subtitle: '2024/07/08' ,
        text: '第2P月極駐車場18000円、第3P月極駐車場13000円空きあります、お早めに。TEL072-757-4013もしくはメールでお問合せ下さい',
      },
      {
        src: require('../assets/p1-2.jpg'),
        title: '月極駐車場',
        subtitle: '2023/8/25' ,
        text: '最駅近！第1月極駐車場空きあります、18000円残少！お早めに。TEL072-757-4013もしくはメールでお問合せ下さい',
      }, 
      {
        src: require('../assets/p1-2.jpg'),
        title: '月極駐車場',
        subtitle: '2023/06/25' ,
        text: '第2P裏月極駐車場空きあります、17000円、1台限り！お早めに。TEL072-757-4013もしくはメールでお問合せ下さい',
      },        
      {
        src: require('../assets/p1-2.jpg'),
        title: '月極駐車場',
        subtitle: '2022/11/28' ,
        text: '第2P裏月極駐車場空きあります、17000円、1台限り！お早めに。TEL072-757-4013もしくはメールでお問合せ下さい',
      },      
      {
        src: require('../assets/p1-2.jpg'),
        title: '月極駐車場',
        subtitle: '2022/08/08' ,
        text: '9/1～月極駐車場空きあります！13000円～、お早めに。TEL072-757-4013もしくはメールでお問合せ下さい',
      },
      {
        src: require('../assets/CameraZOOM-20220323150752026.jpg'),
        title: '川西源氏祭り',
        subtitle: '2022/04/08' ,
        text: '4/10(日)源氏祭り行進に伴い昼12時前後の1時間弱は当駐車場は入出庫不可となります',
      },  
      {
        src: require('../assets/CameraZOOM-20211111170113102.jpg'),
        title: '回数券カードがお得！',
        subtitle: '2021/11/12' ,
        text: '8000円の回数券カードなら9200円使えて大変お得！精算の際に駐車券の後に入れて下さい',
      },         
      {
        src: require('../assets/20210712_093043.jpg'),
        title: '第一駐車場',
        subtitle: '2021/10/23' ,
        text: '川西能勢口駅で一番近い月極駐車場です！通勤に便利！',
      },      
      {
        src: require('../assets/p1-2.jpg'),
        title: '月極駐車場',
        subtitle: '2021/08/17' ,
        text: '駅近の月極め駐車場やっと空きがでました！、お早めに。TEL072-757-4013もしくはメールでお問合せ下さい',
      },
      {
        src: require('../assets/CameraZOOM-20210712092637796.jpg'),
        title: 'セコム',
        subtitle: '2021/08/01' ,
        text: '何らかのトラブル時には精算機横のセコム電話にて24時間コール対応しています。遠隔にてゲートバー開放可能',
      },
      {
        src: require('../assets/CameraZOOM-20210712092113909.jpg'),
        title: 'セキュリティ対策',
        subtitle: '2021/07/31' ,
        text: '監視カメラで24時間監視しています(※全面ではありません)',
      },
      {
        src: require('../assets/CameraZOOM-20210712092625052.jpg'),
        title: '回数券値下げ！',
        subtitle: '2021/07/30' ,
        text: '当日最大800円が回数券払いだと688円相当に！',
      },
      {
        src: require('../assets/CameraZOOM-20210712092523441.jpg'),
        title: '回数券でお得',
        subtitle: '2021/07/20' ,
        text: '8,000円回数券は11日駐車できて400円残ります',
      },
      {
        src: require('../assets/CameraZOOM-20210712092055588.jpg'),
        title: '回数券自販機設置',
        subtitle: '2021/07/19' ,
        text: '3,000円回数券（3,300円金額相当分） 5,000円回数券（5,600円金額相当分） 8,000円回数券（9,200円金額相当分）',
      },
      /*
      {
        src: require(''),
        title: '',
        subtitle: '' ,
        text: '',
      },
      */
      

  
    ],
  
  }