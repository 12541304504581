<template>
  <section class="home-ryoukin">


    <v-container>

    <h2 
     style="
      padding: 0.3em;
      background: #2196F3;
      border-left: solid 10px #0D47A1;
     "
     class="white--text text-md-h3 text-h4 font-weight-black"
     >料金案内</h2>


      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
          <img src="../assets/top_teikakaku.gif" alt="" class="img-fluid">
    
          <!-- <p class="text-h4 font-weight-bold red--text">低価格駐車場</p>-->
          <h3
          style="
            border-left: solid 5px #2196F3;
          "
          class="glay darken-3--text text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >低価格駐車場</h3>



          <p>川西第１駐車場では１００円／３０分の低価格で駐車していただく事ができます。川西能勢口駅周辺の駐車場の中では非常に低価格です。ビジネスでのチョット駐車など低コストで駐車していただくことができます。</p>




        </v-col>
      </v-row>
      <AppButton :button-text="buttonText" :url="{name: 'ryoukin'}"/> 

    </v-container>



  </section>
</template>
<!-- <script src="https://cdn.jsdelivr.net/gh/inotom/vue-go-top@v1.0.2/dist/vue-go-top.min.js"></script> -->
<script>

//Vue.use(GoTop);
 
import AppButton from './AppButton.vue'

export default {
  data () {
    return {
      buttonText: '詳しくみる',
      url: '/ryoukin/3'
    }
  },
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>
.home-ryoukin__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
</style>