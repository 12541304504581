<template>
  <section class="home-eigyou">

   

    


    <v-container>

    <h2 
     style="
      padding: 0.3em;
      background: #2196F3;
      border-left: solid 10px #0D47A1;
     "
     class="white--text text-md-h3 text-h4 font-weight-black"
     >営業案内</h2>


      <!-- (STA)カルーセル -->
      <v-row>
        <v-carousel
          cycle

          style="margin: 50px 0 0 0; width: 600px; height: 300px;"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          <v-img v-bind:src="item.src" v-bind:lazy-src="item.src" class="grey lighten-2 red--text" >
            <!-- <v-card-title>営業案内</v-card-title>-->
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="blue-grey"
                 
                ></v-progress-circular>
               
              </v-row>
            </template>
          </v-img>
        </v-carousel-item>
        </v-carousel>
      </v-row>
      <!-- (END)カルーセル -->



      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
      
    
          <h3
          style="
            border-left: solid 5px #2196F3;
          "
          class="glay darken-3--text text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >一時預かり</h3>


          <p>一時預かりにつきましては川西第１駐車場、川西第２駐車場にて駐車していただく事ができます。駅周辺駐車場の中では低価格となっております。また、提携店割引券のご利用枚数に応じて割引させて頂きますので実質0円で駐車して頂く事も可能です。</p>

         <h3
          style="
            border-left: solid 5px #2196F3;
          "
          class="glay darken-3--text text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >月極め</h3>
          
          <p>月極につきましては川西第１駐車場、川西第２駐車場裏、川西第３駐車場、川西第４駐車場に駐車していただく事ができます。料金につきましては１３，０００円／月～となっています。</p>
 



        </v-col>
      </v-row>
      <!-- <AppButton :button-text="buttonText" :url="url"/>-->
      <AppButton :button-text="buttonText" :url="{name: 'about'}"/> 

    </v-container>


  </section>
</template>
<script>
 
import AppButton from './AppButton.vue'
 
export default {
  data () {
    return {
      buttonText: '詳しく見る',
      url: '/about/2',

      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],

      items: [
          {
            src: require("../assets/top_anshin.jpg"),
          },
          {
            src: require('../assets/top_goannai.jpg'),
          },
          {
            src: require('../assets/top_hiroi.gif'),
          },

        ],

      
    }
  },
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>
.home-eigyou__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
</style>