<template>
  <section class="home-map">
 

    <v-container>

    <h2 
     style="
      padding: 0.3em;
      background: #2196F3;
      border-left: solid 10px #0D47A1;
     "
     class="white--text text-md-h3 text-h4 font-weight-black"
     >地図</h2>

      <v-row class="mt-10 home-about__contents">
        <v-col  class="home-about__contents-img">
          <img src="../assets/map.gif" alt="" class="img-fluid">
    
          <!-- <p class="text-h4 font-weight-bold red--text">駅前便利</p> -->
          <h3
          style="
            border-left: solid 5px #2196F3;
          "
          class="glay darken-3--text text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >駅前便利</h3>

          <p>川西能勢口駅近くで通勤通学またお買い物に便利です！</p>





        </v-col>
      </v-row>
      <AppButton :button-text="buttonText" :url="{name: 'map'}"/> 

    </v-container>


  </section>
</template>
<script>
 
import AppButton from './AppButton.vue'
 
export default {
  data () {
    return {
      buttonText: '詳しくみる',
      url: '/map/5'
    }
  },
  components: {
    AppButton
  }
}
</script>

<style lang="scss" scoped>
.home-map__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
</style>