<template>

  <section class="about">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
    <!-- router-linkで！！下記 v-slotとexact使用が肝らしい！-->
      <template>
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
            exact
              :disabled="item.disabled"
              :to="item.to"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>

      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
          <img src="../assets/top_goannai.jpg" alt="" class="img-fluid">

          <!-- <p>userId is {{$route.params.userId}} </p> -->
    
          <p class="text-h4 font-weight-bold red--text">一時預かり</p>
          <p>一時預かりにつきましては川西第２駐車場にて駐車していただく事ができます。駅周辺駐車場の中では低価格となっております。また、提携店割引券のご利用枚数に応じて割引させて頂きますので実質0円で駐車して頂く事も可能です。梅田やガーデンズへのお買物にも便利です。</p>
          <p class="text-h4 font-weight-bold red--text">月極め</p>
          
          <p>月極につきましては川西第１駐車場、川西第２駐車場裏、川西第３駐車場、川西第４駐車場に駐車していただく事ができます。料金につきましては１３，０００円／月～となっています。</p>
 


        </v-col>
      </v-row>

      <p class="text-h4 font-weight-bold red--text">第一駐車場</p>
      <v-row class="mb-15">
        
        <v-col cols="12" md="3" class="home-about__contents-img">
          <img src="../assets/p1-1.jpg" alt="" class="img-fluid">
          <p>月極専用の駐車場です</p>
        </v-col>
        <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p1-2.jpg" alt="" class="img-fluid">
          <p>白線が見やすく駐車しやすい！平面式で階段昇降不要、高齢者に親切！</p>
        </v-col>
         <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p1-3.jpg" alt="" class="img-fluid">
          <p>阪急川西能勢口駅から近い！</p>
        </v-col> 
      </v-row>

      <p class="text-h4 font-weight-bold red--text">第二駐車場</p>
      <v-row class="mb-15">
        
        <v-col cols="12" md="3" class="home-about__contents-img">
          <img src="../assets/p2-1.jpg" alt="" class="img-fluid">
          <p>一時預かりの駐車場。南出入口、北入り口がございます。</p>
        </v-col>
        <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p2-2.jpg" alt="" class="img-fluid">
          <p>広い出入口</p>
        </v-col>
         <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p2-3.jpg" alt="" class="img-fluid">
          <p>明るく広い通路で女性・高齢者に好評です。また、1階は屋根付きですので雨の日も便利です。</p>
        </v-col> 
        <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p2-4.jpg" alt="" class="img-fluid">
          <p>2階も明るく広い通路で女性・高齢者に好評です。</p>
        </v-col> 
      </v-row>

      <p class="text-h4 font-weight-bold red--text">第三駐車場</p>
      <v-row class="mb-15">
        
        <v-col cols="12" md="3" class="home-about__contents-img">
          <img src="../assets/p3-1.jpg" alt="" class="img-fluid">
          <p>市役所北側の道を入ります。</p>
        </v-col>
        <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p3-2.jpg" alt="" class="img-fluid">
          <p>能勢電車の線路沿いに少し北上します。</p>
        </v-col>
         <v-col cols="12"  md="3" class="home-about__contents-img">
          <img src="../assets/p3-3.jpg" alt="" class="img-fluid">
          <p>北上した能勢電車の線路沿いに位置します。</p>
        </v-col> 
      </v-row>

      <p class="text-h4 font-weight-bold red--text">会社概要</p>
      <v-row class="mb-15">
        
        <v-col cols="12"  class="home-about__contents-img text-left">
          
          <p>会社名：有限会社ハシモト</p>
          <p>代表者名：橋本真一</p>
          <p>住所：〒666-0016 兵庫県川西市中央町１－３</p>
          <p>TEL:072-757-4013（川西第1駐車場）</p>
          <p>E-MAIL:info@kawanishi89.com</p>
        </v-col>
      </v-row>

<!--
        <div class="Page-Btn" @click="scrollTop">
          <i class="fas fa-chevron-up Page-Btn-Icon"><v-icon>mdi-arrow-up-bold-outline</v-icon></i>
          
        </div>
        -->

    

    </v-container>
<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'
 
export default {
 

  data () {
    return {
      title: '営業案内',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          to: { name: 'home' },
        },
        {
          text: '営業案内',
          disabled: true,
          to: { name: 'about' },
        },
      ],


    }
  },
  components: {
    AppBackgroundHolder,
    GoTop
  },

  mounted: function(){
		document.title = "川西能勢口駅近い時間貸し、月極め駐車場、提携店多い！";
		document.querySelector("meta[name='description']").setAttribute('content', "時間貸し100円/30分、月極駐車場も安い！通勤通学にも便利です。提携店も多く");
	},

  methods: {
    scrollTop: function(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },

 head: {
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/about/' },
    ],
  }

}
</script>

<style lang="scss" scoped>
.home-about__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}

.Page-Btn{
  position: fixed;
  right: 14px;
  bottom: 14px;
  width: 50px;
  height: 50px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  background: #506dce;
}
.Page-Btn-Icon{
  color: #fff;
  font-size: 16px;
}

</style>