<template>
  <v-app>
   
    <header>
     
      <v-app-bar
        app
        dark
        class="blue"
        height="80"
      >
      
      <h1 class="siteName">{{titlefunc()}}</h1>
      <!-- <h1 class="siteName">id is {{ id }}</h1> -->
       <img class="denwa hidden-sm-and-down" src="@/assets/top_denwa_toumei.gif" height="55px" width="294px" alt="お問い合わせ">

        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <!-- <a href="/"><img src="@/assets/logo_toumei.gif" height="51px" width="210px" alt="川西駐車場"></a> -->
        <router-link v-bind:to="{ name: 'home' }"><img src="@/assets/logo_toumei.gif" height="51px" width="210px" alt="川西駐車場"></router-link> 

        <v-tabs>
          <v-tab
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :to="menuItem.url"
          >
            {{ menuItem.name }}
          </v-tab>
        </v-tabs>

      </v-app-bar>
   
      <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        
      >
        <v-list
          nav
          dense
        >
          <v-list-item-group>

    <v-list-item
      v-for="(menuItem, index) in menuItems"
      :key="index"
      :to="menuItem.url"
    >
      <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
    </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </header>
  </v-app>
</template>

<script>
import constants from '../common/constants'

export default {
  name :  'AppHeader',
  //props: ["id"],

  data () {
    return {
      drawer: false,
      menuItems: constants.menuItems,
      h1title: constants.h1title,
      //sitename: "川西市",
    }
  },
  
  
  methods:  {
      //右上H1にtitle入れる関数
      titlefunc: function() {
        //初期画面はqueryないので最初[0]の配列出す
        var out_title = this.h1title[0].title;

        for(var key in this.h1title) {
          //名前付きルート方法
          //if(this.h1title[key].id == this.$route.params.id ) {
          if(this.h1title[key].name == this.$route.params.name ) {
          //query方法  
          //if(this.h1title[key].id == this.$route.query.id ) {
            //console.log("this.h1title[key].title");
            //console.debug("console debugggggg");
            out_title = this.h1title[key].title;
            //return this.h1title[key].title;
            break;
          }
        }
        return out_title ;
      }



  }

}
</script>

<style lang="scss" scoped>

/** @import "./src/styles/common/common.scss"; */

.v-toolbar__title {
  overflow: visible !important;
  margin-right: 50px !important;
}
 
.siteName {
  font: 10px Arial,sans-serif;
  text-align: right;
  position: absolute;
  top: 0px;
  left: auto;
  right: 5px;
  width: auto;
  padding: 0px 0px 0px 0px ;
  margin: 0px;
  color: #cccccc;  
 
  @include display_pc {
    font: bold 80% Arial,sans-serif;
    text-align: right;
    position: absolute;
    top: 0px;
    left: auto;
    right: 5px;
    width: auto;
    padding: 0px 0px 0px 0px ;
    margin: 0px;
    color: #cccccc;    
  }
}

.denwa {
  position: absolute;
  top: 0px;
  left: auto;
  right: 5px;
  width: auto;
  padding: 0px 0px 0px 0px ;
  margin: 0px;
  
 
  @include display_pc {
    position: absolute;
    top: 20px;
    left: auto;
    right: 5px;
    width: auto;
    padding: 0px 0px 0px 0px ;
    margin: 0px;
    
  }  

}

/**直接書いてもよい、上の@import不要
.v-app-bar__nav-icon {
  @media (min-width: 960px) {
    display: none !important;
  }
}
 
.v-tabs {
  display: none;
 
  @media (min-width: 960px) {
    display: block !important;
  }
}
**/

/** vue.configにグローバル指定エラー！！なので@importして使用する*/
/**エラー解明！vue.config.jsのprependDataが悪かった！！ */
/**additoinalDataや！@import不要 */
.v-app-bar__nav-icon {
  @include display_pc {
    display: none !important;
  }
}
.v-tabs {
  display: none;
 
  @include display_pc {
    display: block !important;
  }
}

</style>