<!-- D20220415 git test --->
<template>
  <section class="home">
    <!-- <h2>{{ this.$store.state.message }}</h2> -->
    <!-- <h2>{{ $route.query.id }}</h2> -->
    <HomeHero></HomeHero>
    <HomeAbout/>
    <HomeEigyou/>
    <HomeRyoukin/>
    <HomeTeikei/>
    <HomeMap/>


<!-- TOPへ戻るボタン -->
<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
>

      </go-top>

  </section>



</template>
<script>
import HomeHero from './HomeHero.vue'
import HomeAbout from './HomeAbout.vue'
import HomeEigyou from './HomeEigyou.vue'
import HomeRyoukin from './HomeRyoukin.vue'
import HomeTeikei from './HomeTeikei.vue'
import HomeMap from './HomeMap.vue'

import GoTop from '@inotom/vue-go-top'

 


export default {
  name: 'Home',

  components: {
    HomeHero,
    HomeAbout,
    HomeEigyou,
    HomeRyoukin,
    HomeTeikei,
    HomeMap,

   GoTop,
   

  },
  mounted: function(){
		document.title = "川西市の川西駐車場 阪急川西能勢口駅近く安い最大料金駐車場・月極駐車場";
		document.querySelector("meta[name='description']").setAttribute('content', "川西能勢口駅近くで安い駐車場、月極駐車場、提携店豊富な駐車しやすい駐車場です。");
	},

    methods : {
    /** 下記と同じ事、省略形
    message () { return this.$store.state.message }
    */
    message : function() {
       //return this.$store.state.message 
       this.$store.commit('setMessage','Hellllo')
    }
  },

  data () {
    return {
      buttonText: '詳しくみる',
    }
  },

  head: {
    /*
    title: {
      inner: 'It will be a pleasure'
    },
    */
    // Meta tags
    meta: [
      //{ name: 'application-name', content: 'Name of my application' },
      //{ name: 'description', content: 'A description of the page', id: 'desc' }, // id to replace intead of create element
      // ...
      // Twitter
      //{ name: 'twitter:title', content: 'Content Title' },
      // with shorthand
      //{ n: 'twitter:description', c: 'Content description less than 200 characters'},
      // ...
      // Google+ / Schema.org
      //{ itemprop: 'name', content: 'Content Title' },
      //{ itemprop: 'description', content: 'Content Title' },
      // ...
      // Facebook / Open Graph
      //{ property: 'fb:app_id', content: '123456789' },
      //{ property: 'og:title', content: 'Content Title' },
      // with shorthand
      //{ p: 'og:image', c: 'https://example.com/image.jpg' },
      // ...
    ],
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/' },

      // ...
    ],

  }

  
}


</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 50px;
}
</style>
