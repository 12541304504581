<template>
  <section class="home-teikei">
    
     <!-- <div class="section__title"> -->
     <!--<div class=" blue text-center pa-3 mt-16"> -->
      <!-- <div class="section__title-text">川西市駐車場は人にやさしい駐車場です</div> -->
      <!-- <div class="red--text text-md-h3 text-h4 font-weight-black">営業案内</div> -->
   
    <!-- <span class="white--text text-md-h3 text-h4 font-weight-black">提携先</span>-->
    
   <!-- </div>-->

  
    <v-container>
      <h2 
      style="
        padding: 0.3em;
        background: #2196F3;
        border-left: solid 10px #0D47A1;
      "
      class="white--text text-md-h3 text-h4 font-weight-black"
      >提携先</h2>


      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
          <img src="../assets/top_teikei.gif" alt="" class="img-fluid">
    
          <!-- <p class="text-h4 font-weight-bold red--text">提携先豊富</p> -->

          <h3
          style="
            border-left: solid 5px #2196F3;
          "
          class="glay darken-3--text text-md-h4 text-h4 font-weight-bold pl-3 mb-5"
          >提携先豊富</h3>          




          <p>駅周辺の駐車場では最大級の約４０店舗と提携しています。銀行・証券（池田泉州銀行、廣田証券、丸三証券）、お買物（西友、ベルフローラ川西、コーヨー）、美容・理容、医院、その他．．．。
 60分割引券のご利用枚数に応じて割引させて頂きます。</p>

 <!--
          <v-btn
class="mx-2"
      fab
      dark
      small
      color="primary"
      absolute
      bottom
      right

  v-scroll="onScroll"
  @click="$vuetify.goTo(0)">
  <v-icon dark>
        mdi-arrow-up
      </v-icon>
</v-btn>
-->


        </v-col>
      </v-row>
      <AppButton :button-text="buttonText" :url="{name: 'tenpo'}"/> 

    </v-container>


  </section>
</template>
<script>
 
import AppButton from './AppButton.vue'
 
export default {
  data () {
    return {
      buttonText: '詳しくみる',
      url: '/tenpo/4'
    }
  },
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>
.home-teikei__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
</style>