<template>

  <section class="ryoukin">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
    <!-- router-linkで！！下記 v-slotとexact使用が肝らしい！-->
      <template>
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
            exact
              :disabled="item.disabled"
              :to="item.to"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>


      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
          <img src="../assets/top_teikakaku.gif" alt="" class="img-fluid">

          <!-- <p>UserId is {{userId}}</p> -->

          <p class="text-h4 font-weight-bold red--text">一時預かり</p>
          <p>(川西第2駐車場)8時～22時まで100円/30分、22時～翌8時まで100円/60分 当日最大800円(日祝は650円) 24時間営業 提携店舗の割引券をお使い頂くとさらに安くなります。
          </p>

          <p class="text-h4 font-weight-bold red--text">回数券がお得です！</p>
          <p>
ご利用回数が多いお客様や毎回の現金払いがめんどうなお客様には回数券をお勧めしています。回数券は3,000円、5,000円、8,000円の3種類があります。
回数券はプリペイドカード(テレホンカード様式)で出庫時に駐車券の後に入れて頂く事で駐車料金が減算されていく方式です。第2駐車場トイレ横の自販機で販売しております。

3,000円回数券（3,300円金額相当分）
5,000円回数券（5,600円金額相当分）
8,000円回数券（9,200円金額相当分）8,000円ですと1日駐車料金800円が688円相当になります！
          </p>
 
          <p class="text-h4 font-weight-bold red--text">月極め</p>
          <p>
            ・１３，０００円／月～ございます。場所は第１、第２裏、第３、第４Ｐまでございます。

当社では月極に関しまして前金、保証金等は一切頂いておりません。翌月分の毎月末のお支払いとなります。
          </p>

        </v-col>
      </v-row>
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'
 
export default {
 

  data () {
    return {
      title: '料金',
      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          to: { name: 'home' },
        },
        {
          text: '料金',
          disabled: true,
          to: { name: 'ryoukin' },
        },
      ],      
    }
  },
  components: {
    AppBackgroundHolder,
    GoTop
  },
  mounted: function(){
		document.title = "川西能勢口駅近くで100円/30分、最大料金安い！月極駐車場も安い！";
		document.querySelector("meta[name='description']").setAttribute('content', "川西駐車場は川西能勢口駅近くで安い時間貸し、月極駐車場です。");
	},

 head: {
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/ryoukin/' },
    ],
  }  

}
</script>