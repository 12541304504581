<template>

  <section class="tenpo">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
     <!-- router-linkで！！下記 v-slotとexact使用が肝らしい！-->
      <template>
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
            exact
              :disabled="item.disabled"
              :to="item.to"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>

      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
          <img src="../assets/top_teikei.gif" alt="" class="img-fluid">
    
          <p class="text-h4 font-weight-bold red--text">豊富な提携店舗数！割引券ご利用でかなりお得です！</p>
          <p>
            ・当駐車場との提携店舗で発行する６０分割引券をご利用頂きますとご利用枚数により駐車料金を割引きするというシステムです。
            １回４枚迄使用できます。数店舗で割引券を発行して頂いた場合、ご利用枚数に応じて割引させて頂きます。割引券発行条件は店舗により異なりますので御確認願います。
            川西能勢口駅近くのゆっくり、おひとりで安い、食べログ掲載店のランチ店、モーニングのあるカフェ、安い、メンズもある美容院等と提携しています。
          </p>
          <p class="text-h4 font-weight-bold red--text">提携店舗</p>
          
            <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            店名
          </th>
          <th class="text-left">
            カテゴリ
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in desserts"
          :key="item.name"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.calories }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
 


        </v-col>
      </v-row>
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>
  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'
 
export default {
 

  data () {
    return {
      title: '提携店舗',

        desserts: [
          {
            name: 'イオンリカー＆ビューティー',
            calories: 'お買物',
          },
          {
            name: '西友',
            calories: 'お買物',
          },

          {
            name: 'ベルフローラ川西',
            calories: 'お買物',
          },
          {
            name: 'コーヨー',
            calories: 'お買物',
          },
          {
            name: '池田泉州銀行',
            calories: '銀行・証券',
          },
          {
            name: '山陰合同銀行',
            calories: '銀行・証券',
          },
          {
            name: '廣田証券',
            calories: '銀行・証券',
          },
          {
            name: '丸三証券',
            calories: '銀行・証券',
          },
          {
            name: '岡鍼灸整骨院',
            calories: '医院',
          },
          {
            name: 'かどの歯科医院',
            calories: '医院',
          },
          {
            name: 'とくなが小児歯科クリニック',
            calories: '医院',
          },
          {
            name: 'だるま整骨院',
            calories: '医院',
          },
          {
            name: 'ライフカイロプラクティックラボ',
            calories: '医院',
          },
          {
            name: '炭火焼鳥専門店あみや',
            calories: 'グルメ',
          },
          {
            name: '中野屋三代目川西店',
            calories: 'グルメ',
          },
          {
            name: 'でんえんらーめん川西店',
            calories: 'グルメ',
          },
          {
            name: 'ネパールキッチンクマリ',
            calories: 'グルメ',
          },
          {
            name: 'たかの友梨ビューティクリニック',
            calories: '美容・理容',
          },
          {
            name: 'キューティビーザヘア',
            calories: '美容・理容',
          },
          {
            name: 'Ｌ2',
            calories: '美容・理容',
          },
          {
            name: '理容ビッグワン',
            calories: '美容・理容',
          },
          {
            name: 'エステスペース',
            calories: '美容・理容',
          },
          {
            name: 'HERBE',
            calories: '美容・理容',
          },
          {
            name: 'aeru',
            calories: '美容・理容',
          },
          {
            name: 'エスポワール',
            calories: '美容・理容',
          },
          {
            name: 'SARAJU',
            calories: '美容・理容',
          },
          {
            name: 'Hair＆Nail MODE K\'s',
            calories: '美容・理容',
          },
          {
            name: 'CUSHU',
            calories: '美容・理容',
          },
          {
            name: 'オリオリビューテーサロン',
            calories: '美容・理容',
          },
          {
            name: 'ドコモショップ川西',
            calories: 'その他',
          },
          {
            name: '三幸福祉カレッジ',
            calories: 'その他',
          },
          {
            name: '極楽湯',
            calories: 'その他',
          },
          {
            name: 'ICHIYOSHI DESIGN SHOP',
            calories: 'その他',
          },
          





        ],

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          to: { name: 'home' },
        },
        {
          text: '提携店舗',
          disabled: true,
          to: { name: 'tenpo' },
        },
      ],

    }
  },
  components: {
    AppBackgroundHolder,
    GoTop
  },
  mounted: function(){
		document.title = "川西能勢口駅駐車場 ランチ、カフェ、ラーメン店等提携店多く";
		document.querySelector("meta[name='description']").setAttribute('content', "川西能勢口駅近くのランチ、カフェ、ラーメン店、銀行、美容室等と提携しています");
	},

 head: {
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/tenpo/' },
    ],
  }  

}
</script>