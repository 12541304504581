<template>

  <section class="map">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
    <!-- router-linkで！！下記 v-slotとexact使用が肝らしい！-->
      <template>
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
            exact
              :disabled="item.disabled"
              :to="item.to"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>     

      <p class="text-h4 font-weight-bold red--text">地図</p>

      <img src="../assets/map.gif" alt="地図" class="img-fluid" width="478" height="435" border="0" usemap="#Map">
      <map name="Map">
          <area shape="circle" coords="153,32,11" href="#1">
          <area shape="circle" coords="269,37,11" href="#2">
          <area shape="circle" coords="301,48,12" href="#3">
          <area shape="circle" coords="155,90,10" href="#4">
          <area shape="circle" coords="156,292,9" href="#5">
      </map>

      <v-row class="mb-15">
        <v-col cols="12" md="4" class="home-about__contents-img">
        <p><a name="1"></a><img src="../assets/map-5.jpg" width="328" height="248"></p>
        <p><span class="bigtext"><a></a>①</span><span class="storyLeft2 ">産業道路からの第２駐車場北入口への抜け道です。これは便利です！</span></p>
        </v-col>
        <v-col cols="12" md="4" class="home-about__contents-img">
        <p><a name="2"></a><img src="../assets/map-4.jpg" width="328" height="248"></p>
        <p><span class="bigtext"><a></a>②</span><span class="storyLeft2 ">第２駐車場北入口の入り口です。</span></p>
        </v-col>
        <v-col cols="12" md="4" class="home-about__contents-img">
        <p><a name="3"></a><img src="../assets/map-3.jpg" width="328" height="248"></p>
        <p><span class="bigtext"><a></a>③</span><span class="storyLeft2 ">第２駐車場北入口への市役所側からの抜け道です。</span></p>
        </v-col>
        <v-col cols="12" md="4" class="home-about__contents-img">
        <p><a name="4"></a><img src="../assets/map-2.jpg" width="328" height="248"></p>
        <p><span class="bigtext"><a></a>④</span><span class="storyLeft2 ">川西市北中部からは産業道路の川西市市役所西の交差点を左折します。</span></p>
        </v-col>
        <v-col cols="12" md="4" class="home-about__contents-img">
        <p><a name="5"></a><img src="../assets/map-1.jpg" width="328" height="248"></p>
        <p><span class="bigtext"><a></a>⑤</span><span class="storyLeft2 ">産業道路の阪急川西能勢口駅の高架横の一方通行道路を曲がります。</span></p>
        </v-col>

      </v-row>


      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
    
          <p class="text-h4 font-weight-bold red--text">GoogleMap</p>
          <p>一時預かりにつきましては川西第１駐車場、川西第２駐車場にて駐車していただく事ができます。駅周辺駐車場の中では低価格となっております。また、提携店割引券のご利用枚数に応じて割引させて頂きますので実質0円で駐車して頂く事も可能です。</p>
          <div class="gmap">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.053117437789!2d135.41375331521112!3d34.82975698040158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000f728dcad62b1%3A0x400be239f20e5a95!2z5bed6KW_56ysMumnkOi7iuWgtA!5e0!3m2!1sja!2sjp!4v1626006306518!5m2!1sja!2sjp" width="100%" height="auto" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </v-col>
      </v-row>
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'
 
export default {
 

  data () {
    return {
      title: '地図',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          to: { name: 'home' },
        },
        {
          text: '地図',
          disabled: true,
          to: { name: 'map' },
        },
      ],      
    }
  },
  components: {
    AppBackgroundHolder,
    GoTop
  },
  mounted: function(){
		document.title = "川西能勢口駅近くの駐車場で便利！";
		document.querySelector("meta[name='description']").setAttribute('content', "阪急川西能勢口駅近くの川西駐車場です。通勤通学や梅田、ガーデンズへのお買物にも便利な場所です。");
	},
 head: {
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/map/' },
    ],
  }

}
</script>

<style lang="scss" scoped>
  .gmap {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  }
  .gmap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  }
</style>
