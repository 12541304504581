<template>

  <section class="site">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <!--
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>
      これと、下でhref指定する
      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          href: './',
          ////: { name: 'home' },
        },
      -->

     <!-- router-linkで！！下記 v-slotとexact使用が肝らしい！-->
      <template>
        <v-breadcrumbs :items="items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
            exact
              :disabled="item.disabled"
              :to="item.to"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
     

      <v-row class="home-about__contents">
        <v-col  class="home-about__contents-img">
    

          <div><router-link v-bind:to="{ name: 'home' }" class="text-body1" style="color:black; text-decoration: none; ">・Home</router-link></div>
          <div><router-link v-bind:to="{ name: 'about' }" class="text-body1" style="color:black; text-decoration: none; ">・営業内容</router-link></div>
          <div><router-link v-bind:to="{ name: 'ryoukin' }" class="text-body1" style="color:black; text-decoration: none; ">・料金</router-link></div>
          <div><router-link v-bind:to="{ name: 'tenpo' }" class="text-body1" style="color:black; text-decoration: none; ">・店舗</router-link></div>
          <div><router-link v-bind:to="{ name: 'map' }" class="text-body1" style="color:black; text-decoration: none; ">・Map</router-link></div>
          <div><router-link v-bind:to="{ name: 'site' }" class="text-body1" style="color:black; text-decoration: none; ">・サイトマップ</router-link></div>
          <div><router-link v-bind:to="{ name: 'toiawase' }" class="text-body1" style="color:black; text-decoration: none; ">・お問合せ</router-link></div>

        </v-col>
      </v-row>
    

    </v-container>

<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>

  </section>

</template>
<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'
 
export default {


  data () {
    return {
      title: 'サイトマップ',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          to: { name: 'home' },
        },
        {
          text: 'サイトマップ',
          disabled: true,
          to: { name: 'site' },
        },
      ],      
    }
  },
  components: {
    AppBackgroundHolder,
    GoTop
  },
    mounted: function(){
		document.title = "川西駐車場のサイトマップ";
		document.querySelector("meta[name='description']").setAttribute('content', "サイトマップ - 川西市の川西駐車場");
	},


  head: {
    /*
    title: {
      inner: 'It will be a pleasure'
    },
    */
    // Meta tags
    meta: [
      //{ name: 'application-name', content: 'Name of my application' },
      //{ name: 'description', content: 'A description of the page', id: 'desc' }, // id to replace intead of create element
      // ...
      // Twitter
      //{ name: 'twitter:title', content: 'Content Title' },
      // with shorthand
      //{ n: 'twitter:description', c: 'Content description less than 200 characters'},
      // ...
      // Google+ / Schema.org
      //{ itemprop: 'name', content: 'Content Title' },
      //{ itemprop: 'description', content: 'Content Title' },
      // ...
      // Facebook / Open Graph
      //{ property: 'fb:app_id', content: '123456789' },
      //{ property: 'og:title', content: 'Content Title' },
      // with shorthand
      //{ p: 'og:image', c: 'https://example.com/image.jpg' },
      // ...
    ],
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/site/' },

      // ...
    ],

  }



}
</script>