<template>
  <section class="home-about">
     <!-- <div class="section__title"> -->
     <div class="text-center pt-16"> 
      <!-- <div class="section__title-text">川西市駐車場は人にやさしい駐車場です</div> -->
       <div class="red--text text-md-h3 text-h4 font-weight-black">川西能勢口駅前駐車場です</div>
    </div>
    <v-container>
      <v-row>
        <v-col>
        <p class="text-justify">
          当日最大800円（回数券払いなら688円相当！）、通勤、梅田へのお買物にも便利です。月極駐車場は駅周辺に5か所（13,000円/月~）。提携店には食べログ掲載店のランチ、カフェ、ラーメン店、メンズの美容室等があります。
        </p>
        </v-col>
      </v-row>



      <!-- (STA)slide-groupでcard  -->
      <v-row>

      <v-slide-group multiple show-arrows>
       
        <v-slide-item  v-for="(newsItem, index) in newsItems"
      :key="index">
      
      <!--  <v-slide-item  v-for="newsItem in newsItems" v-bind:key="newsItem"> -->


        <v-card
          shaped
          style="margin:10px; width: 250px; height: 400px;"
        >
          <v-img
            class="white--text align-end"
            height="200px" 
            v-bind:src="newsItem.src"
            position="bottom 40% right 50%"
          >
            <v-card-title>{{ newsItem.title }}</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0">
            {{ newsItem.subtitle }}
          </v-card-subtitle>

          <v-card-text class="text--primary">
            <div>{{ newsItem.text }}</div>
          </v-card-text>

    
        </v-card>

        </v-slide-item>
      </v-slide-group>       

      </v-row>
      <!-- (END)slide-groupでcard  -->

    </v-container>
  </section>
</template>
<script>
import constants from '../common/constants'

export default {
  name :  'HomeAbout',
  data : function() {
    return {
      newsItems: constants.newsItems

    }
  },
}


</script>
<style lang="scss" scoped>
.home-about__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
</style>