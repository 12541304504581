//D20220416 GIT追加5
import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import About from './components/About.vue'
import Ryoukin from './components/Ryoukin.vue'
import Tenpo from './components/Tenpo.vue'
import Map from './components/Map.vue'
import Site from './components/Site.vue'
import Toiawase from './components/Toiawase.vue'
//import Database from './components/Database.vue'
 
Vue.use(Router)

export default new Router({
  mode: 'history', //historyにしないとＵＲＬに#つく
  routes: [
    {
      path: '/',
      //path: '/:id',
      name: 'home',
      component: Home,
      //props: true,
     // params: {id : 123}
      //props: true
    },
    {
      //path: '/about/:id',
      path: '/about',
      name: 'about',
      component: About,
      //props:true,
    },
    {
      //path: '/ryoukin/:id',
      path: '/ryoukin',
      name: 'ryoukin',
      component: Ryoukin,
      //props: true,
    },
    {
      //path: '/tenpo/:id',
      path: '/tenpo',
      name: 'tenpo',
      component: Tenpo,
      //props: true,
    },
    {
      path: '/map',
      name: 'map',
      component: Map,
      //props: true,
    },
    {
      path: '/site',
      name: 'site',
      component: Site,
      //props: true,
    },
    {
      path: '/toiawase',
      name: 'toiawase',
      component: Toiawase,
      //props: true,
    },
    /*
    {
      path: '/database',
      name: 'database',
      component: Database
    },
    */
  ],

  /**  エラーなるので消した */
scrollBehavior (to) {
  // ハッシュがある時にはその地点へとスクロールする
  return to.hash ? { selector: to.hash, offset: { x: 0, y: 64 } } : { x: 0, y: 0 }
}


})