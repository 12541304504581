<template>

  <section class="site">
    <AppBackgroundHolder :title="title"/>
  
    <v-container>
      <!-- パンくず -->
      <div>
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      </div>


      <template>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="name"
            :counter="10"
            :rules="nameRules"
            label="Name"
            required
          ></v-text-field>


          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
<!--
          <v-select
            v-model="select"
            :items="selects"
            :rules="[v => !!v || 'Item is required']"
            label="Item"
            required
          ></v-select>
        -->

        <v-textarea
          name="input-7-1"
          label="内容"
          :rules="textboxareaRules"
          v-model="textboxarea"
          value=""
          prepend-inner-icon="mdi-comment"
          hint="お問い合わせありがとうございます"

          auto-grow
          outlined
          rows="3"
          row-height="25"
          shaped
        ></v-textarea>

          <v-checkbox
            v-model="checkbox"
            :rules="[v => !!v || 'チェックが必要です!']"
            label="送信して宜しいですか?"
            required
          ></v-checkbox>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="clickbtn_send"
          >
            送信する
          </v-btn>

          <v-btn
            color="error"
            class="mr-4"
            @click="reset"
          >
            リセットする
          </v-btn>
<!--
          <v-btn
            color="warning"
            @click="resetValidation"
          >
            Reset Validation
          </v-btn>
-->

        </v-form>
      </template>


    

    </v-container>

<go-top
  max-width="50"
  bg-color="#2196F3"
  :size="50"
></go-top>

  </section>

</template>

<script>
import AppBackgroundHolder from './AppBackgroundHolder.vue'
import GoTop from '@inotom/vue-go-top'

import axios from 'axios'

export default {


  data () {
    return {
      title: 'お問合せ',

      //パンくず
      items: [
        {
          text: 'Home',
          disabled: false,
          href: '../',
        },
        {
          text: 'お問合せ',
          disabled: true,
          href: './toiawase',
        },
      ],
      
      valid: true,
      name: '',
      nameRules: [
        v => !!v || '名前が必要です',
        v => (v && v.length <= 10) || '名前は10文字以下です',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mailが必要です',
        v => /.+@.+\..+/.test(v) || 'E-mail形式が誤っています',
      ],
      textboxarea: '',
      textboxareaRules: [
        v => !!v || '内容を入力して下さい',
        v => (v && v.length <= 1000) || '内容は1000文字以下です',
      ],

      select: null,
      selects: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: false,
 

    }
  },

  components: {
    AppBackgroundHolder,
    GoTop,
    //Axios,
  
  },
    mounted: function(){
		document.title = "川西駐車場のお問合せ";
		document.querySelector("meta[name='description']").setAttribute('content', "川西市の川西駐車場へのお問合せのページです。");

	},

    methods: {

      validate () {
        alert("validate")
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },

      onclick : function(e) {
        /*
        if (this.$refs.test_form.validate()) {
          //ここに正常処理入れる
        }
        */

        console.log(e)
        //let name = this.name
        //let email = this.email
        //alert(this.name)
        //alert(this.email)

        fetch('https://www.kawanishi89.com/upload.php', {
          method:'POST',
          body1: this.name,
          body2: this.email,
        })
        .then((res) => res.text())

        .then(console.log)

        .catch(function(error) {
          window.alert('Error: ' + error.message);
        });

      },

        
        clickbtn_send: function () {
            
					//mailerrorboxとnameerrorboxがそれぞれfalseであれば、エラー無しと判断。
					//if(mailerrorbox == "false" && nameerrorbox == "false"){

          if (this.$refs.form.validate()) {
            //alert(this.email)
            //alert(this.name);
						//axiosという機能を使ってPHPファイルにデータを送信します。
						//メールはPHPなどのバックエンド側の言語でしか送れません。
						//よって、Vue.jsからPHPにデータを渡す必要があります。
						//下記はデータをPHPに投げる時の1セットだと思ってください。
						let params = new URLSearchParams();
						//mailareaという箱の中にdata、mailareに入力された内容を入れます。
						params.append('mailarea', this.email);
						//nameareaという箱の中にdata、nameareaに入力された内容を入れます。
						params.append('namearea', this.name);
						//textboxareaという箱の中にdata、textboxareaに入力された内容を入れます。
						params.append('textboxarea', this.textboxarea);
						//axiosという機能を使いデータを投げます。
						axios.post('functions/mail.php', params)
						//PHPで処理された結果がresponseに帰ってきます。
						.then(function (response) {
							//PHPで処理された結果はresponse.dataで呼び出せます。
							//今回は、送信完了メッセージが入っています。
							//詳しくはPHPファイルを確認してみてください。
							//alertで送信完了メッセージを出します。
							alert(response.data)
							//フォームのトップページにリダイレクトします。
							document.location = "./"
						})
						.catch(function() {

							//何かエラーが起きたらconsole.logにエラーを表示させます。
							console.log("error");
						})
					//} else {
          //  alert("error");
          }

				},   


      /*
      submit () {
        this.$refs.observer.validate()
      },
      clear () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.select = null
        this.checkbox = null
        this.$refs.observer.reset()
      },
      */
    },

 head: {
    // link tags
    link: [
      { rel: 'canonical', href: 'https://www.kawanishi89.com/toiawase/' },
    ],
  }    
  

}
</script>