<template>

  <v-app>
    <AppHeader/>
    <!--<Home/>-->
    <v-content>
      <router-view/>
    </v-content>
    <AppFooter/>
  </v-app>
  
  <!--
    <div id="app">
    <h1>{{ $store.state.message }}</h1>
  </div>
  -->
</template>
 
<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
//import Home from  './components/Home.vue'


 
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    
    //Home,
  
  },
  /*
data: () => {
  return {
    fab: false
  };
},
methods: {
  onScroll (e){
    if (typeof window === 'undefined') return
    const top = window.pageYOffset ||   e.target.scrollTop || 0
    this.fab = top > 10
  }
}
*/

}
</script>

<!--  これ入れないと初期値で100vxだと空白できる！-->
<style>
div > .v-application--wrap {
  min-height: 100%;
}
</style>